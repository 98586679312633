import React from 'react';
import Header from './components/Header';



function App() {
  return (
    <div className="App">
     <div className='body'>
      <Header />
     </div>
    </div>
  );
}

export default App;
