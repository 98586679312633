import React from 'react'

const Header = () => {
    return (
        <div className='header'>
            <img class='avatar' src='' alt='' />
            <h2>Cori Sternberg</h2>
            <p>Front End Developer</p>

            <div className='link'>
                <span className='github'>
                    <a>
                        <i class="fab fa-github"></i>
                    </a>
                </span>
                <span className='linkedin'>
                    <a>
                        <i class="fab fa-linkedin-in"></i>
                    </a>
                </span>
                <span className='twitter'>
                    <a>
                        <i class="fab fa-twitter">
                        </i>
                    </a>
                </span>
            </div>
        </div>
    )
}

export default Header
